<script lang="ts">
    import applyStyle from 'Lib/Utils/ApplyStyle';
    import * as Style from 'Components/Style';
    import tooltip from 'Lib/Utils/Tooltip';
    import type {Nullable} from 'Lib/Utils/Nullable';

    export let backgroundColor: Nullable<string> = Style.colors.primary;
    export let activeBackgroundColor: Nullable<string> = Style.colors.primary;
    export let textColor: Nullable<string> = Style.textColors.primary;
    export let borderRadius: Nullable<string> = Style.button.borderRadius;
    export let padding: Nullable<string> = Style.button.padding;
    export let margin: Nullable<string> = undefined;
    export let isFullSize: boolean = false;
    export let minWidth: Nullable<string> = Style.button.minWidth;
    export let isActive: boolean = false;
    export let type: Nullable<'reset' | 'button' | 'submit'> = undefined;
    export let isDisabled: boolean = false;
    export let tooltipContent: Nullable<string> = undefined;

    export let alignSelf: 'center' | 'inherit' = 'inherit';

    $: style = {
        color: textColor,
        fontFamily: Style.text.family,
        borderRadius,
        backgroundColor: isActive ? activeBackgroundColor : backgroundColor,
        padding,
        minWidth,
        margin,
        alignSelf,
    };
</script>

<button
        class:isActive
        class:isFullSize
        disabled={isDisabled}
        {type}
        on:click
        use:applyStyle={style}
        use:tooltip={tooltipContent}>
    <slot>Button</slot>
</button>

<style>
    button {
        margin: 0;
        border: none;
        cursor: pointer;
        width: fit-content;
    }

    button:hover {
        box-shadow: inset 0 0 0 20rem rgba(255, 255, 255, 0.3);
    }

    button:active {
        box-shadow: inset 0 0 0 20rem rgba(0, 0, 0, 0.3);
    }

    button:disabled {
        box-shadow: inset 0 0 0 20rem rgba(255, 255, 255, 0.7);
    }

    .isFullSize {
        width: 100%;
    }

    .isActive {
        color: var(--primary-text-color) !important;
    }
</style>
