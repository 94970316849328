<script lang="ts">
    import applyStyle from 'Lib/Utils/ApplyStyle';
    import * as Style from 'Components/Style';
    import type { Nullable } from 'Lib/Utils/Nullable';

    export let weight: Nullable<string> = Style.text.weight;
    export let size: Nullable<string> = Style.text.size;
    export let color: Nullable<string> = Style.textColors.default;
    export let textTransform: Nullable<string> = Style.text.textTransform;
    export let textAlign: Nullable<string> = Style.unset;
    export let padding: Nullable<string> = Style.text.padding;
    export let lineHeight: Nullable<string> = Style.text.lineHeight;
    export let shadow: Nullable<string> = 'none';
    export let display: Nullable<string> = 'initial';

    $: style = {
        fontWeight: weight,
        fontSize: size,
        textTransform,
        textAlign,
        lineHeight,
        padding,
        color,
        textShadow: shadow,
        display,
    };
</script>

<span use:applyStyle={style}>
    <slot />
</span>
