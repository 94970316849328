﻿import SingletonDependency from 'Lib/SingletonDependency';
import DependencyContainer from 'Lib/DependencyContainer';
import PublicationListContext from 'Pages/PublicationPage/Lib/PublicationListContext';
import {
    ApiException,
    Scenario,
    ScenarioCloneabilityChangeRequest,
    ScenarioPasswordUpdateRequest,
    ScenariosClient
} from 'Generated/RestClient.g';
import NotificationService from 'Lib/Services/NotificationService';
import translations from 'Assets/i18n';
import UserService from 'Lib/Services/UserService';
import Sorter from 'Pages/PublicationPage/Lib/Sorter';
import type {ColumnKey} from 'Pages/PublicationPage/Lib/ColumnKeys';
import {EventDefinition} from 'Lib/EventDefinition';

export default class PublicationListService extends SingletonDependency {
    public onPresentationDeleted: EventDefinition<string> = new EventDefinition<string>();
    private readonly translationContext = translations.pages.publication;
    private readonly publicationListContext: PublicationListContext;
    private readonly notificationService: NotificationService;
    private readonly scenariosClient = new ScenariosClient();
    private readonly sorter: Sorter;

    public constructor(applicationContext: DependencyContainer) {
        super(applicationContext);

        this.publicationListContext = applicationContext.get(PublicationListContext);
        this.notificationService = applicationContext.get(NotificationService);
        const userService = applicationContext.get(UserService);

        userService.onLogin.do(async () => {
            await this.updateScenarioListAsync();
        });

        userService.onLogout.do(() => {
            this.publicationListContext.scenarioList = [];
            this.publicationListContext.pendingUploadCount = 0;
        });

        userService.isUserLoggedInAsync().then(async isLoggedIn => {
            if (isLoggedIn) {
                await this.updateScenarioListAsync();
            }
        });

        this.sorter = new Sorter(this.publicationListContext);
    }

    public async uploadScenarioAsync(file: File, adjustPendingCount: boolean = true): Promise<void> {
        if (adjustPendingCount) {
            ++this.publicationListContext.pendingUploadCount;
        }

        try {
            await this.scenariosClient.createScenario(file.name.replace(/.actg$/, ''), file);

            await this.updateScenarioListAsync();
        } catch (error) {
            if (error instanceof ApiException && error.status === 403) {
                this.notificationService.error(this.translationContext.invalidLicenseError);
            } else {
                console.error('`actg` upload failed.', error);
                this.notificationService.error(this.translationContext.invalidActgFile.format(file.name));
            }
        }

        if (adjustPendingCount) {
            --this.publicationListContext.pendingUploadCount;
        }
    }

    public async updateScenarioListAsync(): Promise<void> {
        const orders = this.publicationListContext.listSorting.map(
            entry => entry.key + (entry.order === 'desc' ? '|desc' : ''),
        );

        const list = await this.scenariosClient.getScenarios(orders);

        this.publicationListContext.scenarioList = list.results as Scenario[];
    }

    public async deleteScenarioAsync(scenarioId: string): Promise<void> {
        await this.scenariosClient.deleteScenario(scenarioId);
        this.onPresentationDeleted.invoke(scenarioId);
        return this.updateScenarioListAsync();
    }

    public downloadScenario(scenarioId: string): void {
        window.open(`/api/scenarios/${scenarioId}/package`, '_blank');
    }

    public openScenario(scenarioId: string): void {
        window.open(this.getScenarioURI(scenarioId), '_blank');
    }

    public async copyScenarioURIAsync(scenarioId: string): Promise<void> {
        await navigator.clipboard.writeText(this.getScenarioURI(scenarioId));
        this.notificationService.info(this.translationContext.publicationURICopied);
    }

    public async uploadScenariosAsync(files: FileList): Promise<void> {
        this.publicationListContext.pendingUploadCount += files.length;
        for (const file of Array.from(files)) {
            await this.uploadScenarioAsync(file, false);
            --this.publicationListContext.pendingUploadCount;
        }
    }

    public setPublicationPassword(id: string, password?: string) {
        return this.scenariosClient.setScenarioPassword(id, new ScenarioPasswordUpdateRequest({password}));
    }

    public setScenarioCloneability(scenarioId: string, isCloneable: boolean) {
        return this.scenariosClient.setScenarioCloneability(scenarioId, new ScenarioCloneabilityChangeRequest({
            isCloneable: isCloneable,
        }));
    }

    getPublicationPassword(id: string) {
        return this.scenariosClient.getScenarioPassword(id);
    }

    async copyEmbedCodeAsync(scenarioId: string) {
        await navigator.clipboard.writeText(this.getEmbedCode(scenarioId));
        this.notificationService.info(this.translationContext.embedCodeCopied);
    }

    public async toggleSort(key: ColumnKey) {
        this.sorter.toggleSort(key);
        return this.updateScenarioListAsync();
    }

    private getScenarioURI(scenarioId: string): string {
        return `${window.location}#/${scenarioId}`;
    }

    private getEmbedCode(scenarioId: string): string {
        return `<iframe src="${window.location}s/${scenarioId}?autostart=false&disableSlideNavigation=false&slide=1" height="320" width="480" style="border: none;"></iframe>`;
    }
}
