﻿import SingletonDependency from 'Lib/SingletonDependency';
import {get, writable, type Writable} from 'svelte/store';
import type {KeyOrderPair} from 'Lib/Models/Order';
import type {ColumnKey} from 'Pages/PublicationPage/Lib/ColumnKeys';
import DependencyContainer from 'Lib/DependencyContainer';
import type {ISupportsListSorting} from 'Pages/PublicationPage/Lib/ISupportsListSorting';
import {CustomLink} from 'Generated/RestClient.g';

export default class CustomLinkContext extends SingletonDependency implements ISupportsListSorting {
    public customLinksListObservable: Writable<CustomLink[]> = writable([]);
    public itemToEditObservable: Writable<CustomLink | undefined> = writable(undefined);
    public newLinkObservable: Writable<boolean> = writable(false);
    public linkToDeleteObservable: Writable<CustomLink | undefined> = writable(undefined);
    public pendingLinksCountObservable: Writable<number> = writable(0);
    public editorErrorTextObservable: Writable<string | undefined> = writable(undefined);
    public listSortingObservable: Writable<KeyOrderPair<ColumnKey>[]> = writable([
        {key: 'createdAt', order: 'desc'},
    ]);

    public get customLinksList() {
        return get(this.customLinksListObservable);
    }

    public set customLinksList(val: CustomLink[]) {
        this.customLinksListObservable.set(val);
    }

    public get listSorting(): KeyOrderPair<ColumnKey>[] {
        return get(this.listSortingObservable);
    }

    public set listSorting(scenarioListSorting: KeyOrderPair<ColumnKey>[]) {
        this.listSortingObservable.set(scenarioListSorting);
    }

    public set pendingLinksCount(val: number) {
        this.pendingLinksCountObservable.set(val);
    }
    
    public set editorErrorText(val: string | undefined) {
        this.editorErrorTextObservable.set(val);
    }
    
    public set itemToEdit(val: CustomLink | undefined){
        this.itemToEditObservable.set(val);
    }
}
