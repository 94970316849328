import SingletonDependency from 'Lib/SingletonDependency';
import type DependencyContainer from 'Lib/DependencyContainer';
import {
    ApiException,
    AuthenticationClient,
    LoginRequest, User
} from 'Generated/RestClient.g';
import {EventDefinition} from 'Lib/EventDefinition';
import UserContext from 'Lib/UserContext';
import NotificationService from 'Lib/Services/NotificationService';
import type LoginProvider from 'Lib/LoginProvider';
import {LoginProvidersMap} from 'Lib/Services/LoginProvidersMap';
import {translations} from 'Assets/i18n/en-US';

const LOGIN_PROVIDER_KEY = 'loginProvider';

export default class UserService extends SingletonDependency {
    public onLogout: EventDefinition<void> = new EventDefinition<void>();
    public onLogin: EventDefinition<User> = new EventDefinition<User>();

    private readonly authenticationClient = new AuthenticationClient();
    private readonly userContext: UserContext;
    private readonly notificationService: NotificationService;

    private resolveProviders: ((value: (PromiseLike<Map<string, LoginProvider>> | Map<string, LoginProvider>)) => void) | undefined;
    private rejectProviders: ((reason?: any) => void) | undefined;
    public readonly availableProviders = new Promise<Map<string, LoginProvider>>((res, rej) => {
        this.resolveProviders = res;
        this.rejectProviders = rej;
    });

    private currentLoginProvider: LoginProvider | undefined;
    private isUserLoggedInPromise: Promise<boolean> | undefined;

    public constructor(applicationContext: DependencyContainer) {
        super(applicationContext);
        this.userContext = applicationContext.get(UserContext);
        this.notificationService = applicationContext.get(NotificationService);
        this.getAvailableAuthenticationProviders().then();
    }

    public async setProvider(name: string) {
        this.currentLoginProvider = (await this.availableProviders).get(name);
    }

    public async getAvailableAuthenticationProviders(): Promise<void> {
        try {
            const providers = await this.authenticationClient.getAvailableProviders();
            const map = new Map<string, LoginProvider>();
            for (const provider of providers) {
                if (!provider.type) continue;
                map.set(provider.type, LoginProvidersMap[provider.type](provider.parameters));
            }
            this.resolveProviders?.(map);
        } catch (e) {
            console.error(e);
        }
    }

    public async isUserLoggedInAsync(): Promise<boolean> {
        if (this.userContext.currentUser) return true;

        if (!this.isUserLoggedInPromise)
            this.isUserLoggedInPromise = this.getCurrentUserOrAttemptAutoLoginAsync();

        return this.isUserLoggedInPromise;
    }

    public async extendSession(): Promise<boolean> {
        try {
            const response = await this.authenticationClient.getCurrentUser();
            return !!response.user;
        } catch (e) {
            return false;
        }
    }

    private async getCurrentUserOrAttemptAutoLoginAsync() {
        try {
            const userResponse = await this.authenticationClient.getCurrentUser();
            this.userContext.currentUser = userResponse.user;
            return true;
        } catch {
        }
        if (this.userContext.autoLoginAllowed) return this.autoLoginAsync();

        return false;
    }

    public async loginAsync(silent = false, data?: any): Promise<boolean> {
        if (!this.currentLoginProvider) throw new Error('No login provider set');

        try {
            const loginRequest =
                silent
                    ? await this.providerSilentLoginAsync()
                    : await this.providerLoginAsync(data);

            const userObject = await this.authenticationClient.login(loginRequest!);
            if (this.userContext.autoLoginAllowed) localStorage.setItem(LOGIN_PROVIDER_KEY, this.currentLoginProvider.key);
            this.userContext.currentUser = userObject;
            this.onLogin.invoke(userObject);
            return true;
        } catch (error) {
            if (error instanceof ApiException && error.status == 401) {
                console.log('Login failed');
                this.notificationService.error(translations.loginFrame.messages.loginFailed);
            }
        }

        return false;
    }

    public async logoutAsync(): Promise<boolean> {
        try {
            await this.providerLogoutAsync();
            await this.authenticationClient.logout();
            this.onLogout.invoke();
            this.userContext.currentUser = undefined;
            return true;
        } catch (error) {
            console.log('Logout failed');
        }
        return false;
    }

    private async providerLoginAsync(data?: any): Promise<LoginRequest | undefined> {
        if (!this.currentLoginProvider) throw new Error('No login provider set');

        return this.currentLoginProvider.login(data, this.userContext.autoLoginAllowed);
    }

    private async providerSilentLoginAsync() {
        if (!this.currentLoginProvider) throw new Error('No login provider set');

        return this.currentLoginProvider?.silentLogin();
    }

    private async providerLogoutAsync() {
        this.currentLoginProvider?.logout();
        localStorage.removeItem(LOGIN_PROVIDER_KEY);
    }

    private async autoLoginAsync() {
        try {
            if (!localStorage[LOGIN_PROVIDER_KEY]) return false;
            if (!this.currentLoginProvider) {
                await this.setProvider(localStorage[LOGIN_PROVIDER_KEY]);
            }
            await this.loginAsync(true);
            const userResponse = await this.authenticationClient.getCurrentUser();
            this.userContext.currentUser = userResponse.user;
            return true;
        } catch (e) {
            console.warn('Auto login failed', e);
            return false;
        } finally {
            window.postMessage('loginFinished', {targetOrigin: '*'});
        }
    }
}
