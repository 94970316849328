import DependencyContainer from 'Lib/DependencyContainer';

import PublicationListService from 'Pages/PublicationPage/Lib/Services/PublicationListService';
import PublicationListContext from 'Pages/PublicationPage/Lib/PublicationListContext';
import PresentationService from 'Pages/PresentationPage/Lib/Services/PresentationService';
import NotificationService from 'Lib/Services/NotificationService';
import UserService from 'Lib/Services/UserService';
import UserContext from 'Lib/UserContext';
import PresentationContext from 'Pages/PresentationPage/Lib/PresentationContext';
import PresentationBootstrapService from 'PresentationBoostrap/Lib/Services/PresentationBootstrapService';
import UserSessionKeepaliveService from 'Lib/Services/UserSessionKeepaliveService';
import CustomLinkContext from 'Pages/PublicationPage/Lib/CustomLinkContext';
import CustomLinkService from 'Pages/PublicationPage/Lib/Services/CustomLinkService';

export const applicationContextKey = DependencyContainer.name;

export function registerDependencies(): DependencyContainer {
    const applicationContext = new DependencyContainer();

    // Contexts
    applicationContext.registerSingleton(UserContext);
    applicationContext.registerSingleton(PublicationListContext);
    applicationContext.registerSingleton(PresentationContext);
    applicationContext.registerSingleton(CustomLinkContext);

    // Services
    applicationContext.registerSingleton(NotificationService);
    applicationContext.registerSingleton(UserService);
    applicationContext.registerSingleton(PublicationListService);
    applicationContext.registerSingleton(CustomLinkService);
    applicationContext.registerSingleton(PresentationService);
    applicationContext.registerSingleton(PresentationBootstrapService);
    applicationContext.registerSingleton(UserSessionKeepaliveService);

    return applicationContext;
}
