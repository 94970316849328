﻿import type {Order} from 'Lib/Models/Order';
import type {ISupportsListSorting} from 'Pages/PublicationPage/Lib/ISupportsListSorting';
import type {ColumnKey} from 'Pages/PublicationPage/Lib/ColumnKeys';

const ordersSequence: Array<Order | undefined> = [undefined, 'asc', 'desc'];

export default class Sorter {
    private context: ISupportsListSorting;
    private readonly isMultiSortAllowed;

    constructor(context: ISupportsListSorting, isMultiSortAllowed: boolean = false) {
        this.context = context;
        this.isMultiSortAllowed = isMultiSortAllowed;
    }

    toggleSort(key: ColumnKey): void {
        const currentOrder = this.getOrder(key);
        if (!this.isMultiSortAllowed) {
            this.context.listSorting = [];
        } else if (currentOrder !== undefined) {
            this.context.listSorting.splice(
                this.context.listSorting.findIndex(entry => entry.key == key),
                1,
            );
        }

        const nextOrder = this.getNextOrder(currentOrder);
        if (nextOrder !== undefined) {
            this.context.listSorting.push({
                key: key,
                order: nextOrder,
            });
        }

        this.context.listSorting = this.context.listSorting;
    }

    getNextOrder(currentOrder: Order | undefined): Order | undefined {
        const nextOrderIndex = ordersSequence.indexOf(currentOrder) + 1;

        return ordersSequence[nextOrderIndex > ordersSequence.length ? 0 : nextOrderIndex];
    }

    getOrder(key: string): Order | undefined {
        return this.context.listSorting.find(entry => entry.key === key)?.order;
    }
}
