import SingletonDependency from 'Lib/SingletonDependency';
import {
    ApiException,
    InstanceStatus,
    ScenariosClient,
    ScenarioStartRequest,
    ScenarioStatus
} from 'Generated/RestClient.g';
import type DependencyContainer from 'Lib/DependencyContainer';
import PresentationContext from 'Pages/PresentationPage/Lib/PresentationContext';
import translations from 'Assets/i18n';
import type {Nullable} from 'Lib/Utils/Nullable';

export default class PresentationBootstrapService extends SingletonDependency {
    private readonly pollIntervalInMs: number = 1000;
    private readonly presentationPattern = /^(?:#|\/s)\/([a-z0-9\-_]+)$/i;
    private readonly translationContext = translations.pages.presentation;

    private readonly scenarioClient = new ScenariosClient();
    private readonly presentationContext: PresentationContext;

    public constructor(applicationContext: DependencyContainer) {
        super(applicationContext);

        this.presentationContext = applicationContext.get(PresentationContext);
    }

    public async startBootstrapProcessAsync(password?: string): Promise<void> {
        const scenarioId = this.getScenarioId();
        this.presentationContext.presentationLoaded = false;

        if (!scenarioId) {
            this.presentationContext.instanceError = this.translationContext.missingScenarioId;
            return;
        }

        try {
            this.presentationContext.showLoadingIndicator = true;
            const result = await this.scenarioClient.startScenario(scenarioId, new ScenarioStartRequest({
                password,
            }));

            switch(result.scenarioStatus){
                case ScenarioStatus.Started:
                case ScenarioStatus.AlreadyRunning:
                    this.waitForInstance(result.scenarioId ?? scenarioId);
                    break;
                case ScenarioStatus.NoContent:
                    this.displayNoContent();
                    break;
            }
        } catch (e) {
            if (e instanceof ApiException) {
                switch (e.status) {
                    case 404:
                        this.presentationContext.instanceError = translations.pages.presentation.missingScenario;
                        return;
                    case 401:
                        if (this.presentationContext.passwordNeeded) {
                            this.presentationContext.passwordError = this.translationContext.passwordPromptDialog.incorrectPassword;
                            return;
                        }

                        this.presentationContext.passwordNeeded = true;
                        return;
                    case 503:
                        this.presentationContext.instanceError = translations.pages.presentation.concurrentLimitReached;
                        return;
                    default:
                        this.presentationContext.instanceError = translations.pages.presentation.unknownError;
                        return;
                }
            }
        } finally {
            this.presentationContext.showLoadingIndicator = false;
        }
        this.presentationContext.passwordNeeded = false;
    }

    public getScenarioId(): Nullable<string> {
        const urlParams = new URLSearchParams(window.location.search);
        const foundScenarioId = urlParams.get('scenarioId');
        if (foundScenarioId !== null) {
            return foundScenarioId;
        }

        const matcher =
            this.presentationPattern.exec(location.pathname) ?? this.presentationPattern.exec(location.hash);

        if (matcher !== null) {
            return matcher[1];
        }

        return undefined;
    }

    private waitForInstance(scenarioId: string): void {
        setTimeout(async () => {
            try {
                const response = await this.scenarioClient.getInstanceStatus(scenarioId);
                switch (response.status) {
                    case InstanceStatus.Starting:
                        break;
                    case InstanceStatus.Ready:
                        this.presentationContext.presentationLoaded = true;
                        return;
                    case InstanceStatus.Occupied:
                        if (this.presentationContext.isEmbedded) this.presentationContext.presentationLoaded = true;
                        else this.presentationContext.instanceError = this.translationContext.presentationAlreadyRunning;
                        return;
                    case InstanceStatus.Error:
                    case undefined:
                        this.presentationContext.instanceError = this.translationContext.genericCommunicationError;
                        return;
                }
            } catch (e) {
                if (e instanceof ApiException && e.status === 404) {
                    this.presentationContext.instanceError = this.translationContext.missingScenario;
                    return;
                }
            }

            this.waitForInstance(scenarioId);
        }, this.pollIntervalInMs);
    }

    private displayNoContent() {
        this.presentationContext.instanceError = this.translationContext.customLinkHasNoPresentation;
    }
}
