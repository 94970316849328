﻿import LoginProvider from 'Lib/LoginProvider';
import {LoginRequest} from 'Generated/RestClient.g';

export default class UserListLoginProvider implements LoginProvider {
    static readonly staticKey = 'user-list';
    readonly key = UserListLoginProvider.staticKey;

    async login(data: any): Promise<LoginRequest | undefined> {
        return new LoginRequest({credentials: {name: data.username}});
    }

    async logout(): Promise<void> {
    }

    get name(): string {
        return 'default';
    }

    async silentLogin(): Promise<LoginRequest | undefined> {
        return undefined;
    }

}
