import SingletonDependency from 'Lib/SingletonDependency';
import DependencyContainer from 'Lib/DependencyContainer';
import PresentationContext from 'Pages/PresentationPage/Lib/PresentationContext';
import UserContext from 'Lib/UserContext';
import {
    ApiException,
    ScenarioSaveAsNewRequest,
    ScenariosClient
} from 'Generated/RestClient.g';
import NotificationService from 'Lib/Services/NotificationService';
import translations from 'Assets/i18n';
import UserService from 'Lib/Services/UserService';

export default class PresentationService extends SingletonDependency {
    private readonly presentationHashPattern: RegExp = /^#\/([a-z0-9\-_]+)/i;
    private readonly translationContext = translations.pages.presentation;

    private readonly presentationContext: PresentationContext;
    private readonly userContext: UserContext;
    private readonly notificationService: NotificationService;

    private readonly scenariosClient = new ScenariosClient();

    public constructor(applicationContext: DependencyContainer) {
        super(applicationContext);

        this.presentationContext = applicationContext.get(PresentationContext);
        this.userContext = applicationContext.get(UserContext);
        this.notificationService = applicationContext.get(NotificationService);
        const userService = applicationContext.get(UserService);

        userService.onLogin.do(async () => {
            if (this.isPresentationOpen()) {
                await this.updateCurrentScenarioAsync();
                this.updatePresentationWritableState();
            }
        });

        userService.onLogout.do(() => {
            this.presentationContext.currentScenario = undefined;
            this.updatePresentationWritableState();
        });

        userService.isUserLoggedInAsync().then(async isLoggedIn => {
            if(this.isPresentationOpen()) {
                if (isLoggedIn) {
                    await this.updateCurrentScenarioAsync();
                    this.updatePresentationWritableState();
                }else{
                    await this.updateCurrentScenarioPublicDataAsync();
                }
            }
        });
    }

    private updatePresentationWritableState(): void {
        this.presentationContext.presentationWritable =
            !!this.userContext.currentUser &&
            this.presentationContext.currentScenario?.ownerId === this.userContext.currentUser.id;
    }

    public isPresentationOpen(): boolean {
        return this.presentationHashPattern.exec(window.location.hash) !== null;
    }

    public getCurrentScenarioId(): string {
        const match = this.presentationHashPattern.exec(window.location.hash);
        if (!match) {
            throw new Error('Could not find scenario id.');
        }

        return match[1];
    }

    public async saveAsync(): Promise<void> {
        try {
            await this.scenariosClient.saveInstanceChanges(this.getCurrentScenarioId());
            this.notificationService.success(this.translationContext.saveSucceeded);
        } catch (e) {
            this.notificationService.error(this.translationContext.saveFailed);
        }
    }

    public async exportToPdf(): Promise<void> {
        try {
            this.presentationContext.setGlobalTextOverlay = this.translationContext.exportInProgress;
            const result = await fetch(`/api/scenarios/${this.getCurrentScenarioId()}/export/pdf`, {
                method: 'GET',
            });
            
            if(result.ok) {
                this.notificationService.success(this.translationContext.saveSucceeded);
                window.open(window.URL.createObjectURL(await result.blob()));
            }else{
                this.notificationService.error(this.translationContext.pdfExportFailed);
            }
        } catch (e) {
            this.notificationService.error(this.translationContext.pdfExportFailed);
        } finally {
            this.presentationContext.setGlobalTextOverlay = undefined;
        }
    }
    
    public async saveAsNew(requester: string): Promise<string|undefined> {
        try {
            const result = await this.scenariosClient.saveAsNew(this.getCurrentScenarioId(), new ScenarioSaveAsNewRequest({
                requester: requester,
            }));
            
            return result.copiedScenarioId;
        } catch (e) {
        }
        
        return undefined;
    }

    private async updateCurrentScenarioAsync(): Promise<void> {
        try {
            this.presentationContext.currentScenario = await this.scenariosClient.getScenario(
                this.getCurrentScenarioId(),
            );
            await this.updateCurrentScenarioPublicDataAsync();
        } catch (error) {
            if (error instanceof ApiException && error.status === 404) {
                this.presentationContext.currentScenario = undefined;
            }
        }
    }

    private async updateCurrentScenarioPublicDataAsync(): Promise<void> {
        try {
            this.presentationContext.currentScenarioPublicData = await this.scenariosClient.getScenarioPublicData(
                this.getCurrentScenarioId(),
            );
        } catch (error) {
            if (error instanceof ApiException && error.status === 404) {
                this.presentationContext.currentScenario = undefined;
            }
        }
    }
}
