﻿import SingletonDependency from 'Lib/SingletonDependency';
import DependencyContainer from 'Lib/DependencyContainer';
import UserContext from 'Lib/UserContext';
import UserService from 'Lib/Services/UserService';
import UserSessionStateMessage from 'Lib/Models/UserSessionStateMessage';

function now() {
    return new Date().getTime();
}

const CHECK_INTERVAL = 15_000;

export default class UserSessionKeepaliveService extends SingletonDependency {
    static readonly PRINT_PREFIX = `[${UserSessionKeepaliveService.name}]`;

    private readonly userContext: UserContext;
    private readonly userService: UserService;
    private readonly broadcastChannel: BroadcastChannel;

    private lastCheckTimestamp = now();

    constructor(applicationContext: DependencyContainer) {
        super(applicationContext);
        this.userContext = applicationContext.get(UserContext);
        this.userService = applicationContext.get(UserService);
        this.broadcastChannel = new BroadcastChannel(`AG-${UserSessionKeepaliveService.name}`);

        window.addEventListener('visibilitychange', this.onVisibilityChange.bind(this));
        setInterval(this.extendSession.bind(this), CHECK_INTERVAL);

        this.broadcastChannel.addEventListener('message', this.onBroadcastMessage.bind(this));
    }

    private async extendSession() {
        if(this.userContext.currentUser === undefined) return;

        const timestamp = now();
        if (timestamp - this.lastCheckTimestamp < CHECK_INTERVAL) {
            this.print('No need to extend yet');
            return;
        }

        this.print('Extending session...');
        this.lastCheckTimestamp = now();
        const success = await this.userService.extendSession();

        this.broadcastTimestamp(timestamp, success);

        if (!success) {
            await this.userService.logoutAsync();
        }
    }

    private async onVisibilityChange() {
        if (document.visibilityState !== 'visible') return;
        await this.extendSession();
    }

    private print(...args: unknown[]) {
        console.log(UserSessionKeepaliveService.PRINT_PREFIX, ...args);
    }

    private broadcastTimestamp(timestamp: number, success: boolean) {
        this.broadcastChannel.postMessage(new UserSessionStateMessage({timestamp, success}));
        this.print('Message sent');
    }

    private async onBroadcastMessage(e: MessageEvent) {
        const message = e.data as UserSessionStateMessage;

        this.lastCheckTimestamp = e.data.timestamp;
        if (!message.success) await this.userService.logoutAsync();
        this.print('Message received');
    }
}
